import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import { default as FormMixin, required } from "@/components/Form/Form.mixin";
export default {
  mixins: [ModalMixin, FormMixin],
  data() {
    return {
      fields: {
        name: this.field({
          validators: [required()]
        }),
        inn: this.field({
          validators: [required()]
        }),
        kpp: this.field(),
        ogrn: this.field({
          validators: [required()]
        }),
        address: this.field({
          validators: [required()]
        })
      }
    };
  },
  mounted() {},
  methods: {
    onOpen({
      data = {}
    }) {
      Object.keys(data).forEach(key => {
        if (this.fields[key]) {
          this.fields[key].model = data[key];
        }
      });
    },
    async submit({
      close
    }) {
      if (!(await this.validate(true))) {
        return;
      }
      this.$emit("complete", {
        name: this.fields.name.model,
        inn: this.fields.inn.model,
        kpp: this.fields.kpp.model,
        ogrn: this.fields.ogrn.model,
        address: this.fields.address.model
      });
      close();
    }
  },
  computed: {
    actions() {
      return ["cancel", {
        name: "submit",
        text: "Сохранить",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        }
        // loading: this.loading,
      }];
    }
  },
  components: {
    Modal
  }
};